
.setup-width-definitions() {
	@container-width:			1200px;
	@tablet-width: 				900px;
	@tablet-small-width:        760px;
	@big-phone-width:			650px;
	@phone-width:               550px;
	@iphone-4-landscape-width:	480px;
	@iphone-4-width: 			320px;
	@page-side-padding: 			25px;
	@outside-page-padding: 			58px;

}

.setup-width-definitions();


/*
 * The base font size which makes the chosen font match photoshop.
 * For Open Sans this is 7.1pt, for Source Sans Pro this is 7.6pt 
 */
@base-font-size:			7.6pt;

@peach:  					#f3ece3;
//@peach2: 					#e0dad2;
//@peach2: 					rgb(224, 218, 208);
 @peach2: 					rgb(225, 218, 208);
//@peach2: 					#efdfd9;


@blue-testimonial: 			#a9bbd0;
@blue: 						#275589;

@grey-body-text:			black;

.inter {
	font-family: 'Inter', sans-serif;
}
.lora {
	font-family: 'Lora', serif;
}
.poppins {
	font-family: 'Poppins', sans-serif;

}


.source-sans-pro() {
	font-family: 'Source Sans Pro', sans-serif;
}

.open-sans() {
    font-family: 'Open Sans', sans-serif;
}

.menu-link() {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}	

.link-inherit() {
	a {
		.menu-link;
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		font-family: inherit;
	}
}


h1, h2, h3, h4, h5 {
	.link-inherit;
}

body {
	.open-sans;
    color: @grey-body-text;
}
