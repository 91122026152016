

.std-header {
	color: white;
	display: grid;
	grid-template-columns: auto 1fr;
	padding: 20px @page-side-padding 20px @page-side-padding;

	
	.logo {
		color: white;
		text-decoration: none;
		align-self: center;
		img {
			max-width: 100%;
		}
	}
	
	.menu {
		list-style: none;
		align-self: center;
		li {
			margin: 10px 0 10px 10px;
			a {
				.fs(20);
				color: black;
				text-transform: uppercase;
				text-decoration: none;
				font-weight: 800;
				padding-left: 10px;
				padding-right: 10px;
				padding-bottom: 6px;	
				padding-top: 2px;
			}
		}
	}


	nav {
		justify-self: right;
		align-self: center;
		text-align: right;

	}
	.menu {
		display: inline-block;
		margin: 0;
		padding: 0;
		& > li {
			display: inline-block;
			a {
				.curve-button;
				background-color: white;
				color: black;
				font-weight: 300;
			}
			&:first-child {
				a {
					background-color: black;
					color: white;
				}
			}
			
			
			ul {
				position: absolute;
				top: 100%;
				left: -10000px;
				opacity: 0;
				transition: opacity .3s;
				background-color: black;
				color: black;
				text-align: left;
				list-style: none;
				font-size: 70%;
				width: 300px;

			}

			&:hover {
				ul {
					left: auto;
					right: 0;
					opacity: 1;
				}
			}
			
		}
	}
	.tribar {
		display: none;
	}
	
	.small {
		display: none;
	}
	.tablet({
		.menu {
			li {
				a {
					margin-top: 10px;
					margin-bottom: 10px;
				}
			}
		}

	});
	
	.tablet-and-smaller({
		grid-template-columns: 1fr auto;
		column-gap: 50px;
		.menu {
			li {
				a {
					.fs(14);
				}
			}
		}
		.logo {
			// width: 45vw;
		}
	});

	@media (max-width: @tablet-small-width) {
		padding-top: 0px;
		padding-bottom: 0px;
		.menu {
			li {
				display: block;
				a {
					display: block;
					text-align: center;
					padding: 8px 15px;
					.big {
						display: none;
					}
					.small {
						display: inline;
					}
				}
			}
		}
		.logo {
			// width: 35vw;
		}
	}
	
	.big-phone-and-smaller({
		column-gap: 10px;

	});
}

header {
	.std-header;
}



.page-std {
	background-color: @peach2;
	min-height: 100vh;
}

@inner-header-height: 160px;
@inner-header-height-small: 130px;
.inner-header {
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: @peach2;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3;
	
	.big-phone-and-smaller({
		padding-top: 10px;
		padding-bottom: 10px;


	});
}

.jumbo, .inner-headline {
	padding-top: @inner-header-height;
	.big-phone-and-smaller({
		padding-top: @inner-header-height-small;	
	});

}

.jumbo {
	overflow: hidden;
	h1 {
		.fs(70);
	}
	.inner {
		display: grid;
		column-gap: 30px;
		row-gap: 30px;
	}
	
	.left {
		z-index: 2;
	}
	.right {
		position: relative;
		video {
			position: absolute;
			height: 100%;
			left: -20%;
			transform: translateZ(0px);
			opacity: .7;
		}
	}
	.inner {
		grid-template-columns: 3fr 5fr;
	}
	
	.tablet-and-smaller({
		h1 {
			font-size: 6vw;
		}
		.right {
			video {
				left: -35%;
			}
		}
	});
	.big-phone-and-smaller({
		h1 {
			.fs(30);
		}
		.right {
			video {
				left: -46%;
			}
		}
	});
	.phone-and-smaller({
		h1 {
			.fs(30);
		}
		.right {
			video {
				left: -100%;
			}
		}
	})
}

.inner-headline {
	
	.msg {
		background-color: black;
		padding-top: 50px;
		padding-bottom: 50px;
		
		color: white;
	}
	h1 {
		.fs(70);
		margin-top: 0;
	}
	h2 {
		.fs(30);
		margin-top: 0;
		font-weight: 400;
	}
	.inner {
		display: grid;
		column-gap: 120px;
		row-gap: 30px;
	}
	.tablet({
		.inner {
			grid-template-columns: auto auto;
			align-items: center;
		}
	});
	.tablet-and-smaller({
		h1 {
			font-size: 8vw;
			br {
				display: none;
			}
		}
		h2 {
			font-size: 5vw;
		}

	});
}

.swarm-above-the-clouds {
	overflow: hidden;
	.container {
		position: relative;
		padding-top: 80px;
		&:before {
			content: '\00a0';
			height: 0;
			position: absolute;
			top: 0;
			left: 50%;
			width: 100%;
			width: ~"calc(100% + 40px)";
			max-width: 95vw;
			transform: translateX(-50%);
			border-bottom: solid 1px black;
		}

	}
	padding-bottom: 30px;
	h1, h2 {
		text-align: center;
	}
	
	h2 {
		margin-bottom: 80px;
		padding-left: 20px;
		padding-right: 20px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.tablet-and-smaller({
		h2 {
			padding-left: 80px;
			padding-right: 80px;
			// max-width: 80%;
		}
	});
	.big-phone-and-smaller({
		h2 {
			padding-left: 0;
			padding-right: 0;

		}
	})

}


.what-we-hear {
	padding-top: 20px;
	padding-bottom: 80px;
	background: white;
	h1 {
		text-align: center;
	}
}

.experts-say {
	padding-top: 30px;
	h1 {
		text-align: center;
	}
}

.call-to-action {
	margin-top: 60px;
	margin-bottom: 80px;
	.container {
		display: grid;
		column-gap: 20px;
		row-gap: 20px;
		
		.links {
			text-align: center;
		}
	}
	
	.links {
		.tablet({
			white-space: nowrap;

		});
		a {
			display: inline-block;
			.curve-button;
			background-color: white;
			color: black;
			font-weight: 300;
			margin-top: 10px;
			margin-bottom: 10px;
		}
		ul {
			padding: 0;
			margin: 0;
		}
		li {
			display: inline-block;
			margin: 0 10px;
			&:first-child {
				a {
					background-color: black;
					color: white;
				}
			}
		}
	}
	.tablet({
		.container {
			grid-template-columns: 1fr auto 1fr;
		}
	});
	.tablet-and-smaller({
		margin-top: 100px;
		.links {
			grid-row: 1;
		}
		.soc-2 {
			text-align: center;
		}

	});
}

footer {
	background-color: black;
	color: white;
	padding-top: 50px;
	padding-bottom: 70px;
	
	a {
		color: white;
	}
	
	.container {
		display: grid;
		column-gap: 100px;
		row-gap: 30px;
	}
	
	.msg {
		.inter;
		.fs(30);
		font-weight: 700;
		em {
			.lora;
			font-weight: 400;
		}
	}
	
	.logo {
		img {
			max-width: 100%;
		}
	}
	
	.disclaimer {
		.fs(11);
	}

	.bottom {
		.fs(14);
		align-self: end;
	}
	
	.tablet({
		.container {
			grid-template-columns: auto 1fr;
		}
		.brand, .about {
			display: contents;
		}
		
		.bottom {
			grid-row: 2;
			grid-column: 1;
			display: grid;
			grid-template-columns: auto auto;
			
			.copyright {
				text-align: right;
				align-self: end;
			}
		}
		
		.msg {
			grid-column: 2;
			grid-row: 1;
		}
		.disclaimer {
			grid-column: 2;
			grid-row: 2;
			align-self: end;
		}

	});
	
	.tablet-and-smaller({
		padding-left: 40px !important;
		padding-right: 40px !important;
		text-align: center;
		.brand, .about {
			display: contents;
		}
		.bottom {
			text-align: center;
			& > * {
				display: inline-block;
				margin: 10px 10px;
			}
		}
		.msg {
			grid-row: 2;
		}
		

	});
	
}

.gathering-information {
	margin-top: 60px;
	margin-bottom: 60px;

}

.swarm-form {
	display: grid;
	column-gap: 50px;
	row-gap: 10px;
	
	label {
		display: block;
	}
	textarea {
		display: block;
		box-sizing: border-box;
		width: 100%;
	}
	
	.bio {
		.quiet {
			font-size: 0;
		}
		
		input {
			display: block;
			width: 100%;
			box-sizing: border-box;
			background-color: white;
			border-radius: 25px;
			border-width: 0;
			padding: 10px 20px;
			
		}
	}
	
	.comments {
		label {
			.fs(21);
		}
		h2 {
			.fs(30);
			font-weight: 600;
		}
	}
	
	.checks {
		h2 {
			.fs(30);
			font-weight: 600;
		}
		h3 {
			.lora;
			.fs(25);
			font-weight: 300;
		}

		.aligned {
			display: grid;
			grid-template-columns: auto 1fr;
			row-gap: 20px;
			column-gap: 20px;
		}
		label {
			display: contents;
			.fs(21);
		}
		
		.box-holder, .show {
			align-self: center;
		}

		.box-holder {
			padding-top: 7px;
			& > * {
				display: inline-block;
			}
			input[type="checkbox"] {
				width: 1px;
				height: 1px;
				opacity: 0;
			}
			.check-full-picture {
				display: none;
			}
			input[type="checkbox"]:checked + .check-empty-picture {
				display: none;
			}

			input[type="checkbox"]:checked ~ .check-full-picture {
				display: inline-block;
			}
		}
		
	}
	
	.submit-it {
		button {
			display: inline-block;
			margin-top: 40px;
			padding-left: 80px;
			padding-right: 80px;
			background-color: black;
			color: white;
		}
		
		a {
			color: inherit;
		}
	}
	
	.tablet({
		grid-template-columns: auto;
		justify-items: center;
		
		& > * {
			max-width: 100%;
			width: 600px;
		}
		.interests {
			// grid-column: 1;
			// grid-row: 2;
		}
		
		.excites {
			// grid-row: 1 / span 2;
			// grid-column: 2;
		}
	});
}