.std-txt {
	h1 {
		.fs(45);
		.inter;
		line-height: 1.2em;
		em {
			.lora;
			font-weight: 400;
		}
	}
	h2 {
		.fs(30);
		font-weight: 600;
		em {
			.lora;
			font-weight: 400;
		}
	}
	h3 {
		.fs(24);
	}
	p {
		.fs(18);
	}


}

.ib {
	display: inline-block;
}

.freedoms {
	display: grid;
	column-gap: 50px;
	row-gap: 30px;
	.freedom {
		.pic {
			min-height: 100px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			img {
				max-width: 100%;
			}

		}
		ul {
			padding-left: 25px;
		}
		
		li {
			.fs(22);
			margin-bottom: 5px;
		}

	}
	
	.tablet({
		grid-template-columns: repeat(3, 1fr);
		.freedom {
			.bottom {
				margin: auto;
				text-align: center;

			}
			.pic {
				text-align: center
			}
			
			ul {
				text-align: left;
				margin: auto;
				display: inline-block;

			}
		}

	});
	.tablet-and-smaller({
		.freedom {
			display: grid;
			grid-template-columns: 150px auto;
			grid-column: 40px;
			text-align: left;
		}

	});
	
	.big-phone-and-smaller({
		.freedom {
			grid-template-columns: auto;
			h3 {
				text-align: center;
			}
		}

	})
}

.impacts {
	display: grid;
	column-gap: 30px;
	row-gap: 30px;
	.impact {
		text-align: center;
		h2 {
			.fs(36);
			font-weight: 700;
			em {
				.lora;
				font-weight: 400;
			}
		}
		.pic {
			height: 200px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;

		}
		.bottom {
			.fs(24);
			font-weight: 700;
			padding-top: 20px;
			max-width: 250px;
			margin-left: auto;
			margin-right: auto;
		}

	}
	
	.tablet({
		grid-template-columns: repeat(2, 1fr);
		.freedom {
			.pic {
				text-align: center
			}
		}

	});
	.desktop({
		grid-template-columns: repeat(4, 1fr);
		.freedom {
			.pic {
				text-align: center
			}
		}

	});
}

.testimonials {
	display: grid;
	column-gap: 30px;
	row-gap: 30px;
	position: relative;
	
	.testimonial {
		background-color: black;
		color: white;
		.pic {
			img {
				border-radius: 50%;
				width: 110px;
			}
		}
	}
	
	.testimonial {
		padding: 35px 25px 35px 25px;
		display: grid;
		column-gap: 20px;
		row-gap: 20px;
		grid-template-columns: auto 1fr;
		.attribution {
			display: contents;
		}
		
		.identifier {
			color: @blue-testimonial;
			align-self: center;

			.inter;
			.name {
				.fs(14);
				font-weight: 600;
				padding-bottom: 10px;
			}
			.company {
				.fs(14)
			}
		}
		
		.statement {
			.fs(18);
			.lora;
			grid-column: span 2;
			padding-left: 10px;
		}
		
	}
	
	.logo {
		align-self: center;
		justify-self: center;
		text-align: center;
		img {
			max-width: 80%;
		}
	}
	
	.dots {
		@dotSize: 15px;
		position: absolute;
		text-align: center;
		.dot {
			display: inline-block;
			width: @dotSize;
			height: @dotSize;
			margin: 20px 10px;
			border: solid 1px black;
			background-color: white;
			border-radius: 50%;
			
			&.current {
				background-color: black;
			}
		}
	}
	.tablet({
		grid-template-columns: repeat(3, 1fr);
		.dots {
			left: -10000px;
		}
	});
	
	.tablet-and-smaller({
		// background-color: black;
		.testimonial {
			// grid-row: 1;
			// grid-column: 1;
			transition: opacity .6s;
		}
		.logo {
			display: none;
		}
		/*
		.dots {
			left: 0;
			right: 0;
			bottom: -60px;
		}

		
		.slide {
			&.visible {
				opacity: 1;
			}
			&.invisible {
				opacity: 0;
			}
		}
		*/

		

	});
	
	

}

.curve-button {
	.inter;
	.fs(20);
	padding: 12px 30px;
	border-radius: 25px;
	text-transform: uppercase;
	border: black 1px solid;
	text-decoration: none;
}